const config = {
  initiateLoginEndpoint: 'https://ssl-test.x-epic.com/auth-start.html', //process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: '16f1ea4b-90db-4899-ae22-f1eda615f1fe', // process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: undefined, // process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: undefined, // process.env.REACT_APP_FUNC_NAME,


  // initiateLoginEndpoint:  process.env.REACT_APP_START_LOGIN_PAGE_URL,
  // clientId:  "asdsadasd",
  // // clientId:  process.env.REACT_APP_CLIENT_ID,

  // apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  // apiName: process.env.REACT_APP_FUNC_NAME,
};
console.log('config')
console.log(config)
console.log('process')
console.log(process.env)
export default config;
