import { useContext, useState } from "react";
import { Image, TabList, Tab } from "@fluentui/react-components";
import "./Welcome.css";
import { EditCode } from "./EditCode";
import { app } from "@microsoft/teams-js";
import { AzureFunctions } from "./AzureFunctions";
import { Graph } from "./Graph";
import { CurrentUser } from "./CurrentUser";
import { useData } from "@microsoft/teamsfx-react";
import { Deploy } from "./Deploy";
import { Publish } from "./Publish";
import { TeamsFxContext } from "../Context";
import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect } from 'react';

let username1;
let bk_url;
// let oA = document.createElement("a")
export function Welcome(props) {


  // 创建一个 TeamsClient 实例
  microsoftTeams.initialize();
  // const microsoftTeams = require("@microsoft/teams-js");
  // console.log("进入window.parent前");
  // if (window.parent && window.parent.microsoftTeams) {
  //     window.parent.microsoftTeams.getContext(function(context) {
  //     const userName = context.user.displayName;
  //     console.log("当前 Teams 用户的显示名称：" + userName);
  //   });
  // }

  

  // 确保 Teams 客户端已初始化
  

  
  
  const { showFunction, environment } = {
    showFunction: true,
    environment: window.location.hostname === "localhost" ? "local" : "azure",
    ...props,
  };
  
  const friendlyEnvironmentName =
    {
      local: "local environment",
      azure: "Azure environment",
    }[environment] || "local environment";
  
  const { teamsUserCredential } = useContext(TeamsFxContext);
  // alert(friendlyEnvironmentName)


  const { loading, data, error } = useData(async () => {
    // alert('teamsUserCredential')

    // alert(JSON.stringify(teamsUserCredential))
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });

  const userName = loading || error ? "" : data.displayName;

 

  const hubName = useData(async () => {
    await app.initialize();
    const context = await app.getContext();
    return context.app.host.name;
  })?.data;
  const [selectedValue, setSelectedValue] = useState("local");

  const onTabSelect = (event, data) => {
    setSelectedValue(data.value);
  };
  


  function MyComponent() {
    useEffect(() => {
      // 在这里编写副作用操作
      // 这些代码将在组件渲染时执行
      microsoftTeams.getContext((context) => {
        // 获取当前用户的用户名
        // console.log("执行context after")
        username1 = context.userPrincipalName;
        const encodedData = btoa(username1)
        // console.log(encodedData)
        // console.log("执行context 后")
        // console.log(username1)
        // console.log("当前已登录用户信息是：" + context);
        // alert("当前已登录用户的用户名是：" + username)
        // console.log("当前已登录用户的用户名是：" + username);
        var oA = document.createElement("a"); //创建a标签
        oA.href = "http://paas.itcloud.c-epic.com/login/?is_from_logout=1&c_url=http%3A//paas.itcloud.c-epic.com/console/&type=login&username=" + encodedData;  //添加 href 属性
        oA.target = "_blank"; //添加 target 属性
        // console.log("***********")
        // console.log(username1)
        oA.click()
      });
      // 返回一个清理函数（可选）
      return () => {
        // 在组件卸载时执行清理操作
      };
    }, []); }// 传入一个空数组作为依赖项
  

  
 
  bk_url = "http://paas.itcloud.c-epic.com/login/?is_from_logout=1&c_url=http%3A//paas.itcloud.c-epic.com/console/&type=login&username=" + username1;
  return (
    <div className="welcome page">
      <div className="narrow page-padding">
        <Image src="hello1.png" />
        <script>
          function on_bk() {
            
            MyComponent()
          }

        </script>

        {/* <h1 className="center">Congratulations{userName ? ", " + userName : ""}!</h1> */}
        <h1 className="center">Welcome to X-Portal!</h1>

        {/* {hubName && <p className="center">Your app is running in {hubName}</p>}
        <p className="center">Your app is running in your {friendlyEnvironmentName}</p> */}

        <div className="tabList">
          {/* <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Local" value="local">
              1. Build your app locally
            </Tab>
            <Tab id="Azure" value="azure">
              2. Provision and Deploy to the Cloud
            </Tab>
            <Tab id="Publish" value="publish">
              3. Publish to Teams
            </Tab> */}
          {/* </TabList> */}
          <div>
            {selectedValue === "local" && (
              <div>
                <EditCode showFunction={showFunction} />
                
                {/* <p>Failed to open the redirect web, please click </p> */}
                {/* <a href={bk_url} target="_blank" rel="noreferrer">here.</a> */}

                {/* <CurrentUser userName={userName} /> */}
                {/* <Graph /> */}
                {/* {showFunction && <AzureFunctions />} */}
              </div>
            )}
            {/* {selectedValue === "azure" && (
              <div>
                <Deploy />
              </div>
            )}
            {selectedValue === "publish" && ( 
              <div>
                <Publish />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
